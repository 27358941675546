import { globalFetchOptions } from '~/utils';
import { IDisasterPantry, ResponseData } from '~/composables/types/volunteer/DisasterPantry';

export class DisasterPantry {
  public static getBaseUrl = () => {
    const config = useRuntimeConfig();
    return config.public?.volunteerApiBase + '/api/v1';
  };
  public static getToken = () => {
    return {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('disaster-pantry-token')}`,
      },
    };
  };
  public static getDisasterPantryById = async (id: string) => {
    return $fetch<IDisasterPantry>(`/pantries/${id}`, {
      ...globalFetchOptions({ context: 'get distribution center' }),
      method: 'get',
      baseURL: this.getBaseUrl(),
    });
  };

  public static getConfig = async (pantryId: string) => {
    const config = useRuntimeConfig();
    return $fetch<any>(`/configuration/${pantryId}/${config.public.disasterAssistanceTypeId}`, {
      ...globalFetchOptions({ context: 'get disaster config' }),
      method: 'get',
      baseURL: this.getBaseUrl(),
      ...DisasterPantry.getToken(),
    });
  };

  public static authenticate = async (id: string, body: any) => {
    return $fetch<{
      isNew: boolean;
      token: string;
      lastAccessedPantry: any;
      volunteerID: string;
    }>(`/pantries/login`, {
      ...globalFetchOptions({ context: 'authenticate distribution center' }),
      method: 'POST',
      body,
      baseURL: this.getBaseUrl(),
    });
  };
}
